<script setup lang="ts">
import type {
  PdpColorSelectorProps,
  ColorOption,
} from './PdpColorSelector.props'

const props = defineProps<PdpColorSelectorProps>()
const emit = defineEmits<{
  (e: 'update:selectedColor', data: ColorOption): void
}>()

const { closeDialog } = useDialog(props.id)
const { isEmporioArmaniWebsite, isEa7Website } = useWebsite()

const selectedOption = ref<ColorOption | null>(
  props.options.find(option => option.sku === props.skuCode) || null
)

const saveOption = (option: ColorOption) => {
  selectedOption.value = option
}

const confirmColor = () => {
  if (selectedOption.value) {
    emit('update:selectedColor', selectedOption.value)
    selectedOption.value =
      props.options.find(
        option => option.color === selectedOption.value?.color
      ) || null
    closeDialog()
  }
}

const hasExtColors = computed(
  () => props.extColors && props.extColors?.length > 0
)
</script>

<template>
  <OrganismsSidebarSlide
    :id="id"
    :header-props="{
      titleText: $ts('pdp.colorSelect.selectColor'),
      hasBackButton,
    }"
    :footer-props="{ primaryText: $ts('confirm') }"
    :close-every-sidebar="closeEverySidebar"
    @primary-click="confirmColor"
  >
    <template #body>
      <div class="gap-sm mb-4 grid grid-cols-3">
        <div v-for="(option, i) in options" :key="i">
          <input
            :id="option.color"
            v-model="selectedOption"
            :name="'color-selection'"
            :value="option"
            type="radio"
            class="peer absolute z-0 opacity-0"
            :aria-label="option.color"
          />
          <button
            class="ring-primitives-blue gap-sm text-book-6 flex flex-col text-center ring-offset-2 peer-focus-visible:ring-1"
            :class="{
              'cursor-pointer': option.sku !== selectedOption?.sku,
            }"
            tabindex="-1"
            aria-hidden="true"
            @click="saveOption(option)"
          >
            <div
              class="text-text-primary border"
              :class="[
                option.sku === selectedOption?.sku
                  ? 'border-stroke-primary'
                  : 'border-text-secondary',
                { 'p-[2px]': isEa7Website || isEmporioArmaniWebsite },
              ]"
            >
              <div
                v-if="hasExtColors"
                class="ring-background-secondary w-full pt-[100%] ring-2 ring-inset"
                :style="{ backgroundColor: `#${option.hexColor}` }"
                :class="{ 'opacity-20': !option.isInStock }"
              />
              <NuxtPicture
                v-else
                provider="cloudinary"
                :src="option.thumbnailUrl"
                sizes="sm:30vw, md:25vw, lg:12.5vw"
                :class="{ 'opacity-20': !option.isInStock }"
              />
            </div>

            <span
              class="w-full text-center"
              :class="[
                option.isInStock ? 'text-text-primary' : 'text-text-inactive',
                { underline: option.sku === selectedOption?.sku },
              ]"
            >
              {{ option.color }}
            </span>
          </button>
        </div>
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
