<script setup lang="ts">
import type {
  PdpSizeSelectorProps,
  Size,
  extSize,
} from './PdpSizeSelector.props'
const { isPdp } = useRouteHelper()

const props = withDefaults(defineProps<PdpSizeSelectorProps>(), {
  isReserveInStore: false,
})

const emit = defineEmits<{
  'update:selectedSizeIndex': [selectedSizeIndex: number | null]
  'update:selectedCountry': [selectedCountry: string]
  'update:selectedExtSku': [selectedExtSku: string]
  'similar-products': []
}>()

const selectedSizeState = useState<extSize>('selectedSizeState')

const sortSizes = (obj: { [key: string]: Size[] }) => {
  for (const key in obj) {
    obj[key].sort(
      (a: Size, b: Size) =>
        parseInt(a.sizePos ?? '0') - parseInt(b.sizePos ?? '0')
    )
  }
  return obj
}

const checkOutOfStockSize = (sku: string, disabled?: boolean) => {
  if (disabled) return true
  const mySize = props.skuSizes?.find(obj => obj.SKU === sku)
  if (!mySize) return true
  return mySize.avEcom === 0 && mySize.avRet === 0
}

const tabs = computed(() => {
  const sizes = sortSizes(props.sizes)

  return Object.entries(sizes).map(([country, sizes]) => ({
    country,
    options: sizes.map(({ SKU, sku, size, disabled, lastInStock }, index) => ({
      lastInStock: props.isReserveInStore ? false : lastInStock,
      label: size,
      value: index,
      attrs: {
        disabled: props.isReserveInStore
          ? false
          : checkOutOfStockSize(sku ?? SKU!, disabled),
      },
    })),
  }))
})

const countries = computed(() => Object.keys(props.sizes))

const selectedTabIndex = computed({
  get() {
    const selectedCountryIndex = countries.value.indexOf(props.selectedCountry)
    return selectedCountryIndex === -1 ? 0 : selectedCountryIndex
  },
  set(selectedCountryIndex: number) {
    const selectedCountry = countries.value[selectedCountryIndex]
    emit('update:selectedCountry', selectedCountry)
  },
})

const selectedSizeIndex = computed({
  get() {
    // ext sizes
    if (props.extSizes && props.extSizes.length > 0) {
      return selectedSizeState?.value?.value
    }
    // one size
    if (props.isOneSize) return 0
    // default
    return props.selectedSizeIndex ?? undefined
  },
  set(selectedSizeIndex) {
    // one size
    if (props.isOneSize && !props.extSizes) {
      emit('update:selectedSizeIndex', 0)
    } else if (props.extSizes && props.extSizes.length > 0) {
      // ext sizes
      const selDimension: extSize | undefined = props.extSizes.find(
        (obj: extSize) => obj.value === selectedSizeIndex
      )
      if (!selDimension) return
      emit('update:selectedSizeIndex', selDimension.value ?? null)
      emit('update:selectedExtSku', selDimension.SKU ?? '')

      selectedSizeState.value = selDimension
    } else {
      // default
      emit('update:selectedSizeIndex', selectedSizeIndex ?? null)
    }
  },
})

// Composables needed to calculate move and select the right shortcut and handle the transition
const { slider, handleShortcutClick, updateSliderPosition } =
  useSliderTransition(selectedTabIndex.value, false)

onMounted(() => {
  updateSliderPosition(selectedTabIndex.value)
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

const handleResize = () => {
  updateSliderPosition(selectedTabIndex.value)
}
</script>

<template>
  <div>
    <!-- bags sizes flow -->
    <div
      v-if="extSizes && extSizes?.length > 0"
      class="pt-6"
      data-testid="size-option-list"
    >
      <div v-for="option in extSizes" :key="option.value">
        <InputsRadioWithSlot
          v-if="extSizes && extSizes?.length > 0"
          :id="`option-${option.label}`"
          v-model="selectedSizeIndex"
          :label="option.label ?? ''"
          :extra-label="''"
          :ext-sizes="extSizes"
          name="bags_size"
          :value="option.value"
          is-select-size-component
          :src="option.image"
          :price="option.extPrice"
          :old-price="option.extOldPrice"
          :discount="option.extDiscount"
          :av-ret="option.avRet"
          :av-ecom="option.avEcom"
          :dims="option.Dims"
          :out-of-stock="option.outOfStock"
          class="border-primitives-grey-50 border-b px-0"
        >
          <template #extra-label-left>
            <!-- TODO: (tracked #1165) need to pass disabled to ext size -->
            <div
              v-if="option.lastInStock || option.outOfStock"
              class="flex flex-col justify-between"
            >
              <p
                v-if="option.lastInStock && !option.outOfStock"
                class="last-one-label text-light-6"
              >
                {{ $ts('pdp.sizeSelector.lastInStock') }}
              </p>
              <AtomsCta
                v-if="option.outOfStock && isPdp"
                anatomy="link"
                class="similar-product-label"
                @click="$emit('similar-products')"
              >
                <template #label>
                  {{ $ts('pdp.sizeSelector.emptyStock') }}
                </template>
              </AtomsCta>
            </div>
          </template>
        </InputsRadioWithSlot>
      </div>
    </div>

    <UtilsTabs
      v-else
      v-model="selectedTabIndex"
      class-buttons-container="flex slider-tab"
      :tabs="tabs"
    >
      <template #button="{ tab, selected, index }">
        <UITabElement
          class="shortcut-element flex-grow"
          :class="{ 'z-10': selected }"
          :selected="selected"
          :shortcut-index="index"
          @click="handleShortcutClick(index)"
        >
          {{ tab.country }}
        </UITabElement>
      </template>
      <template #tabs-sufix>
        <div ref="slider" class="slider-tab-border" />
      </template>

      <template #panel="{ tab }">
        <div class="pt-6" data-testid="size-option-list">
          <div
            v-for="option in tab.options"
            :key="option.value"
            :data-testid="
              option.attrs.disabled ? 'size-disabled' : 'size-not-disabled'
            "
          >
            <InputsRadioWithSlot
              :id="`option-${option.label}`"
              v-model="selectedSizeIndex"
              name="size"
              :label="option.label ?? ''"
              :extra-label="tab.country"
              :value="option.value"
              :disabled="option.attrs.disabled"
              is-select-size-component
              class="border-primitives-grey-50 border-b px-0"
            >
              <template #extra-label>
                <div class="z-[9999] flex w-full justify-end">
                  <p
                    v-if="option?.lastInStock && !option?.attrs.disabled"
                    class="last-one-label text-light-6"
                  >
                    {{ $ts('pdp.sizeSelector.lastInStock') }}
                  </p>
                  <AtomsCta
                    v-if="option?.attrs.disabled && isPdp"
                    anatomy="link"
                    class="similar-product-label"
                    @click="$emit('similar-products')"
                  >
                    <template #label>
                      {{ $ts('pdp.sizeSelector.emptyStock') }}
                    </template>
                  </AtomsCta>
                </div>
              </template>
            </InputsRadioWithSlot>
          </div>
        </div>
      </template>
    </UtilsTabs>
  </div>
</template>
